import { store } from "@/store/store";
import { dashboard } from "@/shared/dtos/dashboard";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { dispositivo } from "@/shared/dtos/dispositivo";
import { recurso_data } from "@/shared/dtos/recurso_data";
import { DashDraggAndDrop } from "@/shared/dtos/DashDraggAndDrop";
import { UtilsString } from "@/utils/utils-string";
import { BaseDto } from "@/shared/dtos/base-dto";

@Module({
  namespaced: true,
  name: "dashboardModule",
  store,
  dynamic: true,
})
class dashboardModule extends VuexModule {
  public dashboards: dashboard[] = [];
  public dashboards_drag_drop: DashDraggAndDrop[] = [];

  public dashboard: dashboard = new dashboard();
  public dispositivos: dispositivo[] = [];
  public recursos: recurso_data[] = [];
  public last_group: number = -1;
  public primera_vez_entrando: boolean = true;

  @Action({ commit: "onGetdashboards" })
  public async getdashboards() {
    return await ssmHttpService.get(API.dashboard);
  }

  @Action({ commit: "onGetdashboard" })
  public async getdashboard(id: any) {
    return await ssmHttpService.get(API.dashboard + "/" + id);
  }

  @Action({ commit: "onGetdashboarddash" })
  public async getdashboarddash() {
    return await ssmHttpService.get(API.dashboard + "/dragdrop");
  }

  @Action({ commit: "onGetdashboarddash" })
  public async getdashboarddashbyid_group(id_group: number) {
    return await ssmHttpService.get(API.dashboard + "/dragdrop/" + id_group);
  }

  @Action({ commit: "onGetdashboarddash" })
  public async getdashboarddashby_public_id_group(id_group: number) {
    return await ssmHttpService.get(API.public + "/dragdrop/" + id_group);
  }

  @Action({ commit: "onGetdashboarddash" })
  public async getdashboarddashby_cache_public_id_group(id_group: number) {
    return await ssmHttpService.get(API.public + "/dragdrop_cache/" + id_group);
  }

  @Action({ commit: "onGetdashboarddash" })
  public async getdashboarddashby_cache_public_id_group_silecioso(
    id_group: number
  ) {
    return await ssmHttpService.get(
      API.public + "/dragdrop_cache/" + id_group,
      null,
      false
    );
  }

  @Action
  public async set_url_api() {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.put(
      API.public +
        "/url_api?url=" +
        UtilsString.ValueOf(process.env.VUE_APP_ROOT_PATH),
      null
    );
  }

  @Action
  public async guardar_diseno_dashboard(dashboard: DashDraggAndDrop[]) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.put(API.dashboard + "/dragdrop", dashboard);
  }

  @Action({ commit: "onGetdashboard_form" })
  public async getdashboard_form(id: any) {
    return await ssmHttpService.get(API.dashboard + "/data_form/" + id);
  }

  @Action
  public async guardardashboard(dashboard: dashboard) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.dashboard, dashboard.toJson());
    this.getdashboards();
  }

  @Action
  public async modificardashboard(dashboard: dashboard) {
    await ssmHttpService.put(API.dashboard + "/" + dashboard.id, dashboard);
    this.getdashboards();
  }

  @Action
  public async eliminardashboard(dashboard: dashboard) {
    await ssmHttpService.delete(
      API.dashboard + "/" + dashboard.id,
      null,
      false
    );
  }

  @Mutation
  public onGetdashboarddash(res: DashDraggAndDrop[]) {
    this.dashboards_drag_drop = res;
  }
  @Mutation
  public onGetdashboards(res: dashboard[]) {
    this.dashboards = res ? res.map((x) => new dashboard(x)) : [];
  }

  @Mutation
  public onGetdashboard(res: dashboard) {
    this.dashboard = new dashboard(res);
  }

  @Mutation
  public onGetdashboard_form(res: any) {
    this.dispositivos = res.dispositivos;
    this.recursos = res.recursos;
    /*
    this.dispositivos = res.dispositivos
      ? res.dispositivos.map((x: dispositivo) => new dispositivo(x))
      : [];
    this.recursos = res.recursos
      ? res.recursos.map((x: recurso_data) => new recurso_data(x))
      : [];*/
  }

  @Mutation
  public set_primeravez(res: boolean) {
    this.primera_vez_entrando = res;
  }

  @Mutation
  public set_ultimo_grupo_visto(res: number) {
    this.last_group = res;
  }
}
export default getModule(dashboardModule);
