import { BaseDto } from "@/shared/dtos/base-dto";
import { config_gaugesDto } from "./config_gaugesDto";
import { data_sensors } from "./data_sensors";
import { dispositivo_dashboard } from "./dispositivo_dashboard";
import { columna } from "./dynamic_tables/columna";
import { recurso_dashboard } from "./recurso_dashboard";
import { sensor } from "./sensor";

export class dashboard extends BaseDto {
  public nombre!: string;
  public tipo_medicion!: string;
  public id_sensor!: number;
  public tipo!: string;
  public visible_gauges!: boolean;
  public dispositivos!: dispositivo_dashboard[];
  public recursos!:recurso_dashboard[];
  public configuracion_dash!:config_gaugesDto[];
  public sensor!:sensor;
  public datos_sensores!:data_sensors[];
  public datos_sensores_charts !:data_sensors[][];
  public id_grupo_dashboard!:number;
  public info!: string;
  public dias_grafico!:number;  
  public columnas_tabla!:columna[];
  public datos_tabla!:any[];
}
