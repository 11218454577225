














import { RouterNames } from "@/router/routernames";
import { dashboard } from "@/shared/dtos/dashboard";
import { MessageService } from "@/shared/services/message-service";
import dashboardModule from "@/store/modules/dashboard-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
  },
})
export default class DashboardsLista extends Vue {
  nuevo() {
    this.abrir_formulario("-1");
  }

  editar(obj: dashboard) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  eliminar(obj: dashboard) {
    dashboardModule
      .eliminardashboard(obj)
      .then(() => {
        //@ts-ignore
        this.$refs["data_table"].refresh();
      })
      .catch(() => {
        MessageService.toast(
          "No se ha podido eliminar, hay datos asociados",
          MessageService.TypeWarning
        );
      });
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.dashboardsformulario,
      params: { id: id },
    });
  }
}
